@import "_variables.scss";

.next-api-ref {
  font-family: $font-family-regular;
  margin-top: var(--chakra-space-25-6);
  .title {
    font-size: $font-size-2rem;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-large;
    color: $color-default-font-color;
  }
  .reference {
    button {
      font-family: $font-family-regular;
      font-size: $font-size-reg;
      line-height: var(--chakra-space-9-6);
      color: $color-link-hover-color;
      border: 1px solid $color-link-hover-color;
      padding: var(--chakra-space-8) var(--chakra-space-8) var(--chakra-space-8)
        var(--chakra-space-9-6);
      border-radius: 8px;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile-desktop) {
  .next-api-ref {
    margin-top: var(--chakra-space-12-8);
    .reference {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
}
